import axios from 'axios';
import endpoints from '@/js/endpoints';
import GeneralTemplate from '@/js/vuexTemplates/general';
import { getModuleProps, objToCamel, prepareTimeStamps } from '@/js/utils';
import { metricMap } from '@/js/constants';

const rankersState = {
  stats: {},
  computingStats: false,
  fetchingStatisticsSettings: false,
  statisticsSettings: null,
  isFetchingRankerDataSource: false,
  rankerArticles: [],
  rankerArticleAttributes: [],
  stagedRuleHistory: [],
  stopWords: [],
  metaData: {},
  ruleUsageStats: null,
  articleId2DataSourceId: {},
  articleUrl2Id: {},
  runningStatisticsTask: null,
  singleMetricBeingComputed: [],
  stageableVersions: [],
  isFetchingStageableVersions: false,
  datasourceId2Name: {},
};

const getters = {
  isTrainable(state) {
    return state.details.trainable;
  },
  rankerArticleOptions(state) {
    return state.rankerArticles.map((x) => ({ value: x.id, text: `${x.title} (${x.data_source_name}: ${x.external_id})` }));
  },
  rankerArticleAttributeOptions(state) {
    return state.rankerArticleAttributes.map((x) => ({ value: x.id, text: `${x.field}: ${x.value} (${x.data_source_name})` }));
  },
  rankerArticlesDict(state) {
    const dict = {};
    state.rankerArticles.forEach((e) => {
      dict[e.id] = { id: e.id, title: e.title };
    });
    return dict;
  },
  rankerArticlesTranslations(state) {
    const dict = {};
    state.rankerArticles.forEach((e) => {
      dict[e.id] = e.translations;
    });
    return dict;
  },
  getStopWordId(state) {
    return (toFindWord) => state.stopWords.find(({ word }) => word === toFindWord)?.id || null;
  },
  metaData(state) {
    return state.metaData;
  },
  articleId2DataSourceId: (state) => state.articleId2DataSourceId,
  articleUrl2Id: (state) => state.articleUrl2Id,
  deployedRankerInstance(state) {
    for (const instance of Object.values(state.stageableVersions)) {
      if (instance.active) {
        return instance;
      }
    }
    return null;
  },
  deployedTestRankerInstance(state) {
    for (const instance of Object.values(state.stageableVersions)) {
      if (instance.activeForTesting) {
        return instance;
      }
    }
    return null;
  },
};

const mutations = {
  setStats(state, payload) {
    state.stats = payload;
  },
  updateStats(state, payload) {
    const metricKeysToUpdate = Object.keys(payload);
    metricKeysToUpdate.forEach((metric) => {
      state.stats[metric] = payload[metric];
    });
  },
  setComputingStats(state, payload) {
    state.computingStats = payload;
  },
  setSingleMetricCompute(state, { metric, computing }) {
    const index = state.singleMetricBeingComputed.indexOf(metric);
    if (index !== -1) {
      if (computing) return;
      state.singleMetricBeingComputed.splice(index, 1);
      return;
    }
    if (!computing) return;
    state.singleMetricBeingComputed.push(metric);
  },
  setFetchingStatisticsSettings(state, payload) {
    state.fetchingStatisticsSettings = payload;
  },
  setStatisticsSettings(state, payload) {
    state.statisticsSettings = payload;
  },
  setStatisticsSetting(state, { key, value }) {
    state.statisticsSettings[key] = value;
  },
  setRankerArticles(state, payload) {
    state.rankerArticles = payload;
  },
  setRankerArticleAttributes(state, payload) {
    state.rankerArticleAttributes = payload;
  },
  setStagedRuleHistory(state, payload) {
    state.stagedRuleHistory = payload;
  },
  setIsFetchingRankerDataSource(state, payload) {
    state.isFetchingRankerDataSource = payload;
  },
  addStopWord(state, newWordObj) {
    const match = state.stopWords.find((wordObj) => wordObj.id === newWordObj);
    if (!match) state.stopWords.push(newWordObj);
  },
  deleteStopWord(state, wordId) {
    const index = state.stopWords.findIndex((wordObj) => wordObj.id === wordId);
    if (!index >= 0) state.stopWords.splice(index, 1);
  },
  setStopWords(state, wordsList) {
    state.stopWords = wordsList;
  },
  setMetaData(state, payload) {
    state.metaData = payload;
  },
  setRuleUsageStats(state, payload) {
    state.ruleUsageStats = payload;
  },
  setArticlesDict(state, payload) {
    state.articleId2DataSourceId = payload;
  },
  setArticleUrl2Id(state, payload) {
    state.articleUrl2Id = payload;
  },
  setStatisticsTask(state, id) {
    state.statisticsTask = id;
  },
  setStageableVersions(state, payload) {
    state.stageableVersions = payload;
  },
  setIsFetchingStageableVersions(state, payload) {
    state.isFetchingStageableVersions = payload;
  },
  setDatasourceNames(state, payload) {
    state.datasourceId2Name = payload;
  },
};

const actions = {
  async fetchArticleUrl2Id({
    state, rootGetters, commit, dispatch,
  }) {
    try {
      const request = { ...rootGetters['auth/headerAuthorization'] };
      const response = await axios.get(`${endpoints.ranker + state.details.id}/get_article_urls/`, request);
      commit('setArticleUrl2Id', response.data);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch articles',
        text: error.message,
      }, { root: true });
    }
  },
  async fetchDatasourceNames({
    state, rootGetters, commit, dispatch,
  }) {
    try {
      const request = { ...rootGetters['auth/headerAuthorization'] };
      const response = await axios.get(`${endpoints.ranker + state.details.id}/datasource-names/`, request);
      commit('setDatasourceNames', response.data);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch datasource names',
        text: error.message,
      }, { root: true });
    }
  },
  async fetchStatisticsSettings({ dispatch, commit, rootGetters }, rankerId) {
    commit('setFetchingStatisticsSettings', true);
    try {
      const request = { ...rootGetters['auth/headerAuthorization'] };
      request.params = { ranker_id: rankerId };
      const response = await axios.get(`${endpoints.statisticsConfig + rankerId}/`, request);
      commit('setStatisticsSettings', objToCamel(response.data));
      commit('setFetchingStatisticsSettings', false);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch statistics settings',
        text: error.message,
      }, { root: true });
      commit('setFetchingStatisticsSettings', false);
    }
  },
  async updateStatisticsSettings({
    state, dispatch, rootState, rootGetters,
  }) {
    try {
      const auth = rootGetters['auth/headerAuthorization'];
      const response = await axios.patch(
        `${endpoints.statisticsConfig + state.statisticsSettings.id}/`,
        {
          ranker: rootState.ranker.details.id,
          cost_per_query: state.statisticsSettings.costPerQuery,
          currency: state.statisticsSettings.currency,
        },

        auth,
      );
      if (response.status === 200) {
        dispatch('computeRankerStats', rootState.ranker.details.id);
      }
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to update statistics settings',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    }
  },
  trainRanker({ dispatch }, ranker) {
    const endpoint = `${endpoints.ranker}${ranker.id}/train/`;
    dispatch('postTask', {
      endpoint,
      data: {},
      operationType: 'train',
    }, { root: true });
  },
  uploadRankerInstance({ dispatch, commit }, { rankerId, file, description }) {
    commit('rankerInstance/setIsUploading', true, { root: true });
    const endpoint = `${endpoints.ranker}${rankerId}/upload/`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', description);
    dispatch('postTask', {
      endpoint,
      data: formData,
      operationType: 'upload',
    }, { root: true });
  },
  async computeRankerStats({
    commit, state, dispatch, rootGetters,
  }) {
    if (state.computingStats) return;
    commit('setComputingStats', true);
    try {
      commit('setStats', {});
      const auth = rootGetters['auth/headerAuthorization'];
      const filters = rootGetters['statisticsFiltersStore/filters'];
      const formattedStartDate = new Date(filters.startDate).setHours(0, 0, 0, 0);
      const formattedEndDate = new Date(filters.endDate).setHours(23, 59, 59, 999);
      const config = {
        interval_endpoints: prepareTimeStamps({
          startTime: formattedStartDate,
          endTime: formattedEndDate,
          interval: filters.interval.toLowerCase(),
        }),
        start_time: formattedStartDate,
        end_time: formattedEndDate,
        metrics: filters.metrics.map((e) => metricMap[e]),
        user_types: filters.userTypes,
        number_of_topics: null,
      };
      const resp = await axios.post(`${endpoints.ranker}${state.details.id}/stats/`, config, auth);
      if (resp.data.celery_id) {
        commit('setStatisticsTask', resp.data.celery_id);
        dispatch('updateStatisticsStatus');
      }
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to compute statistics.',
        text: error.message,
      }, { root: true });
      commit('setStatisticsTask', null);
      commit('setComputingStats', false);
    }
  },
  async computeSingleStat({
    state, commit, dispatch, rootGetters,
  }, options) {
    const metric = options.metric;
    if (!metric) throw new Error('No metric specified');
    const numberOfTopics = options.numberOfTopics || null;
    try {
      if (state.singleMetricBeingComputed.includes(metric)) return;
      commit('setSingleMetricCompute', { metric, computing: true });
      const auth = rootGetters['auth/headerAuthorization'];
      const filters = rootGetters['statisticsFiltersStore/filters'];
      const formattedStartDate = new Date(filters.startDate).setHours(0, 0, 0, 0);
      const formattedEndDate = new Date(filters.endDate).setHours(23, 59, 59, 999);
      const config = {
        interval_endpoints: prepareTimeStamps({
          startTime: formattedStartDate,
          endTime: formattedEndDate,
          interval: filters.interval.toLowerCase(),
        }),
        start_time: formattedStartDate,
        end_time: formattedEndDate,
        metrics: [metricMap[metric]],
        user_types: filters.userTypes,
        number_of_topics: numberOfTopics,
      };
      const initialResponse = await axios.post(`${endpoints.ranker}${state.details.id}/stats/`, config, auth);
      const celeryId = initialResponse?.data?.celery_id;

      const taskResult = async () => {
        const taskResponse = await dispatch('task/refreshSingleTaskProgress', celeryId, { root: true });
        const taskStatus = taskResponse?.data?.status;
        if (taskResponse.status === 404) {
          throw new Error('Server is busy and could not compute statistics.');
        } else if (taskResponse.status !== 200 || taskStatus === 'failed') {
          throw new Error('The backend failed to compute the statistics.');
        } else if (taskStatus === 'pending' && taskResponse?.data?.isBusy === true) {
          throw new Error('Server is busy and could not compute statistics right now');
        } else if (taskStatus === 'pending') {
          await new Promise((resolve) => { setTimeout(resolve, 2000); });
          return taskResult();
        } else if (taskStatus === 'done') {
          return taskResponse.data.result;
        }
        return null;
      };
      if (celeryId) {
        const updatedStats = await taskResult();
        commit('updateStats', updatedStats);
      }
    } catch (error) {
      const isBusy = error.message.includes('busy');
      dispatch('sidebar/showWarning', {
        title: isBusy ? 'Server is busy' : 'Failed to compute statistics.',
        text: error.message,
        variant: isBusy ? 'warning' : 'danger',
      }, { root: true });
    } finally {
      commit('setSingleMetricCompute', { metric, computing: false });
    }
  },
  async updateStatisticsStatus({ state, commit, dispatch }) {
    try {
      const resp = await dispatch('task/refreshSingleTaskProgress', state.statisticsTask, { root: true });
      if (resp.status === 404) {
        throw new Error('Server is busy and could not compute statistics.');
      } else if (resp.status !== 200 || resp.data.status === 'failed') {
        throw new Error('The backend failed to compute the statistics.');
      } else if (resp.data.status === 'pending' && resp.data.isBusy === true) {
        throw new Error('Server is busy and could not compute statistics right now');
      } else if (resp.data.status === 'pending') {
        commit('setStats', resp.data?.result || {});
        setTimeout(() => {
          dispatch('updateStatisticsStatus');
        }, 2000);
      } else if (resp.data.status === 'done') {
        commit('setStats', resp.data.result);
        commit('setComputingStats', false);
        commit('setStatisticsTask', null);
      }
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to compute statistics.',
        text: error.message,
      }, { root: true });
      commit('setComputingStats', false);
      commit('setStatisticsTask', null);
    }
  },
  async orderCustomRules({ rootGetters, dispatch }, { rankerId, order }) {
    const auth = rootGetters['auth/headerAuthorization'];
    const data = { order };
    try {
      await axios.post(`${endpoints.ranker}${rankerId}/order_custom_rules/`, data, auth);
      dispatch('customRule/fetchItems', { ranker_id: rankerId }, { root: true });
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to order custom rules',
        text: error.message,
      }, { root: true });
      throw error;
    }
  },
  async fetchRankerArticles({ rootGetters, dispatch, commit }, rankerId) {
    try {
      const auth = rootGetters['auth/headerAuthorization'];
      const { data } = await axios.get(`${endpoints.ranker}${rankerId}/get_articles/`, auth);
      commit('setRankerArticles', data);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch articles',
        text: error.message,
      }, { root: true });
      throw error;
    }
  },
  async fetchArticlesDict({ rootGetters, dispatch, commit }, rankerId) {
    try {
      const auth = rootGetters['auth/headerAuthorization'];
      const { data } = await axios.get(`${endpoints.ranker}${rankerId}/get_article_dict/`, auth);
      commit('setArticlesDict', data);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch articles',
        text: error.message,
      }, { root: true });
      throw error;
    }
  },
  async fetchRankerArticleAttributes({ rootGetters, dispatch, commit }, rankerId) {
    try {
      const auth = rootGetters['auth/headerAuthorization'];
      const { data } = await axios.get(`${endpoints.ranker}${rankerId}/get_article_attributes/`, auth);
      commit('setRankerArticleAttributes', data);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch article attributes',
        text: error.message,
      }, { root: true });
      throw error;
    }
  },
  async getStageableVersions({ rootGetters, dispatch, commit }, rankerId) {
    commit('setIsFetchingStageableVersions', true);

    try {
      const auth = rootGetters['auth/headerAuthorization'];
      const { data } = await axios.get(`${endpoints.ranker}${rankerId}/stageable-versions/`, auth);
      const formattedData = data.map((e) => objToCamel(e));
      commit('setStageableVersions', formattedData);

      return formattedData;
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch stageable versions',
        text: error.message,
      }, { root: true });
      throw error;
    } finally {
      commit('setIsFetchingStageableVersions', false);
    }
  },
  async testRanker({ rootGetters, dispatch }, {
    rankerId, text, context, metaData, device, url, save,
    includeGptReply, topk, useUnstagedRules, testModel,
  }) {
    try {
      const auth = rootGetters['auth/headerAuthorization'];
      const data = {
        text,
        context,
        meta_data: metaData,
        test: !save,
        device,
        url,
        use_unstaged_rules: useUnstagedRules,
        test_model: testModel,
      };
      const rankResponse = await axios.post(`${endpoints.rank}${rankerId}/`, data, auth);
      if (includeGptReply) {
        const gptResponse = await axios.post(`${endpoints.rank}${rankerId}/chat/`, {
          messages: [{ content: text, role: 'user' }],
          quid: rankResponse.data.quid,
          include_gpt_reply: true,
          topk,
        }, auth);
        rankResponse.data = { ...gptResponse.data, ...rankResponse.data };
        return rankResponse;
      }
      return rankResponse;
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to test ranker',
        text: error.message,
      }, { root: true });
      throw error;
    }
  },
  async stageRules({ rootGetters, dispatch }, { rankerId, unstage }) {
    try {
      const auth = rootGetters['auth/headerAuthorization'];
      const data = { unstage };
      await axios.post(`${endpoints.ranker}${rankerId}/stage_custom_rules/`, data, auth);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to stage custom rules',
        text: error.message,
      }, { root: true });
      throw error;
    }
  },
  async fetchStagedRuleHistory({ rootGetters, dispatch, commit }, rankerId) {
    try {
      const auth = rootGetters['auth/headerAuthorization'];
      const { data } = await axios.get(`${endpoints.ranker}${rankerId}/staged_rule_history/`, auth);
      commit('setStagedRuleHistory', data);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch staged rule history',
        text: error.message,
      }, { root: true });
      throw error;
    }
  },
  async fetchDataSourceByRanker({ rootGetters, dispatch, commit }, rankerId) {
    try {
      commit('setIsFetchingRankerDataSource', true);
      const auth = rootGetters['auth/headerAuthorization'];
      const { data } = await axios.get(`${endpoints.dataSourceByRanker}${rankerId}/`, auth);
      commit('dataSource/setItemDetails', data, { root: true });
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch ranker data source',
        text: error.message,
      }, { root: true });
      throw error;
    } finally {
      commit('setIsFetchingRankerDataSource', false);
    }
  },
  async handleStopWords({ rootGetters, dispatch, commit }, {
    id = null,
    task = '',
    word = '',
  }) {
    try {
      const auth = rootGetters['auth/headerAuthorization'];
      const endpoint = endpoints.stopWords;
      switch (task) {
        case 'delete':
          if (id === null) throw new Error('No word was selected');
          await axios.delete(`${endpoint + id}/`, auth);
          commit('deleteStopWord', id);
          break;
        case 'add': {
          if (word === '') throw new Error('Word cannot be blank');
          const { data: wordObj } = await axios.post(endpoint, { word }, auth);
          commit('addStopWord', wordObj);
          break;
        }
        default: {
          const { data: stopWordsList } = await axios.get(endpoint, auth);
          commit('setStopWords', stopWordsList);
          break;
        }
      }
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Could not update ignored words',
        text: error.message,
      }, { root: true });
      throw error;
    }
  },
  updateMetaDataKey({ state, commit }, { key, value }) {
    const metaData = state.metaData;
    metaData[key] = value;
    commit('setMetaData', metaData);
  },
  async fetchRuleUsageStats({ rootGetters, commit, dispatch }, rankerId) {
    try {
      const auth = rootGetters['auth/headerAuthorization'];
      const { data } = await axios.get(`${endpoints.ranker}${rankerId}/rule_usage_stats/`, auth);
      commit('setRuleUsageStats', data);
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch rule usage stats',
        text: error.message,
      }, { root: true });
      throw error;
    }
  },
};
const templates = [
  new GeneralTemplate(endpoints.ranker, 'ranker'),
];
export default {
  namespaced: true,
  ...getModuleProps({
    state: rankersState, getters, mutations, actions,
  }, templates),
};
