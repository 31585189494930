export const FIND_ELEMENT = 'findElement';
export const GET_ATTRIBUTE = 'getAttribute';
export const SET_VARIABLE = 'setVariable';
export const ADD_LINK = 'addLink';
export const ADD_ARTICLE = 'addArticle';
export const WAIT = 'wait';
export const CLICK = 'click';
export const CONTROL_FLOW = 'controlFlow';

export const ALL_ACTIONS = [
  FIND_ELEMENT,
  GET_ATTRIBUTE,
  WAIT,
  CLICK,
  ADD_LINK,
  ADD_ARTICLE,
  CONTROL_FLOW,
  SET_VARIABLE,
];

export const FIND_ELEMENT_TYPES = {
  ID: 'id',
  XPATH: 'xpath',
  LINK_TEXT: 'link text',
  PARTIAL_LINK_TEXT: 'partial link text',
  NAME: 'name',
  TAG_NAME: 'tag name',
  CLASS_NAME: 'class name',
  CSS_SELECTOR: 'css selector',
};

export const PREFIX_URL_TYPES = {
  TOPLEVEL: 'toplevel',
  CHILDREN: 'children',
  REGEX: 'regex',
};

export const SITEMAP_URL_TYPES = {
  TOPLEVEL: 'toplevel',
  SITEMAP: 'sitemap',
};

export const EXTRACTOR_TYPES = {
  ARTICLE: 'article-extractor',
  LINK: 'link-extractor',
};

export const ROUTER_MAP = {
  [EXTRACTOR_TYPES.ARTICLE]: 'article-extractor',
  [EXTRACTOR_TYPES.LINK]: 'link-extractor',
};

export const DEFAULT_LINK_EXTRACTOR = Object.freeze({
  type: EXTRACTOR_TYPES.LINK,
  scrapingRule: PREFIX_URL_TYPES.TOPLEVEL,
  crawlingRule: SITEMAP_URL_TYPES.TOPLEVEL,
  includeRegexes: [],
  commandIds: [],
  excludeRegexes: [],
  commands: {},
  apply: [],
});

export const DEFAULT_ARTICLE_EXTRACTOR = Object.freeze({
  type: EXTRACTOR_TYPES.ARTICLE,
  name: 'Extractor Name',
  description: '',
  commandIds: [],
  commands: {},
});
