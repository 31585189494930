<template>
  <div>
    <div :style="scrollable ? `max-height: ${scrollable}px; overflow-y: auto;` : ''" class="mb-2">
      <b-list-group
        class="bg-light r-25 conditions"
      >
        <b-list-group-item
          v-for="(condition, index) in conditions"
          :key="condition.id"
          class="conditions__condition d-flex align-items-center m-1 r-25"
        >
          <span
            v-b-tooltip.viewport.noninteractive
            title="Click to edit"
            class="w-100"
          >
            <div
              class="flex-grow-1 pr-1 cursor-pointer"
              tabindex="0"
              @click="prepareConditionEdit(condition)"
              @keyup.enter="prepareConditionEdit(condition)"
            >
              <span v-if="condition.detect_articles">Detect
                {{ condition.article_ids.length }} articles</span>
              <span v-if="condition.font_size && !condition.text">
                Any text with font size {{ condition.font_size }}
              </span>
              <template v-else>
                <span class="condition__key">{{ condition.text }}</span>
                <span>{{ condition.negate ? ' not in' : ' in' }} {{ fieldDisplay(condition) }}</span>
                <span v-if="condition.case_sensitive"> (case)</span>
                <span v-if="condition.exact_match"> (exact)</span>
                <span v-if="condition.is_regex"> (re)</span>
                <span v-if="condition.font_size"> with font size {{ condition.font_size }} </span>
              </template>
            </div>
          </span>
          <b-button
            v-b-tooltip.viewport.noninteractive
            title="Remove"
            type="button"
            class="btn-invis condition__btn ease-in-out"
            @click="removeCondition(index)"
          >
            <font-awesome-icon
              icon="times"
              style="transform: scale(1.25)"
            />
          </b-button>
        </b-list-group-item>
        <span
          v-if="conditions.length === 0"
          class="ml-1 p-1 d-block"
        >
          <em>No Text Conditions</em>
        </span>
      </b-list-group>
    </div>
    <b-button
      block
      variant="primary"
      @click="$bvModal.show(`createTextCondition${id}`)"
    >
      Add Condition
    </b-button>
    <b-modal
      :id="`createTextCondition${id}`"
      ref="createTextConditionModal"
      title="Condition"
      :ok-disabled="$v.currentCondition.$invalid"
      size="lg"
      @ok="addCondition"
      @hide="resetCondition"
    >
      <b-form-group label="Condition type">
        <b-row>
          <b-col v-if="conditionOptions.includes('regex') && !currentCondition.detect_articles" cols="auto">
            <b-checkbox
              id="isRegex"
              v-model="currentCondition.is_regex"
              switch
            >
              Regex
            </b-checkbox>
          </b-col>
          <b-col v-if="conditionOptions.includes('negate') && !currentCondition.detect_articles" cols="auto">
            <b-checkbox
              id="antiPattern"
              v-model="currentCondition.negate"
              switch
            >
              Negate
            </b-checkbox>
          </b-col>
          <b-col v-if="conditionOptions.includes('caseSensitive') && !currentCondition.detect_articles" cols="auto">
            <b-checkbox
              id="caseSensitive"
              v-model="currentCondition.case_sensitive"
              switch
            >
              Case sensitive
            </b-checkbox>
          </b-col>

          <b-col
            v-if="conditionOptions.includes('exactMatch') && !currentCondition.is_regex && !currentCondition.detect_articles"
            cols="auto"
          >
            <b-checkbox
              id="exactMatch"
              v-model="currentCondition.exact_match"
              switch
            >
              Exact match
            </b-checkbox>
          </b-col>
          <b-col
            v-if="conditionOptions.includes('detectArticle')"
            cols="auto"
          >
            <b-checkbox
              id="detectArticles"
              v-model="currentCondition.detect_articles"
              switch
            >
              Detect article
            </b-checkbox>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        v-if="currentCondition.detect_articles"
        label="Select articles"
        description="If any of the selected articles is in predictions, the condition will be fullfilled."
      >
        <multi-select
          id="fieldValueTargets"
          v-model="currentCondition.article_ids"
          :options="rankerArticleOptions"
          multiple
          selected-on-top
          feedback
          typename="attribute"
          class="w-100"
        />
      </b-form-group>
      <template v-else>
        <b-row>
          <b-col>
            <b-form-group
              label="Pattern"
              label-for="textPattern"
            >
              <b-form-input
                id="textPattern"
                v-model="currentCondition.text"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="allowMetaData && currentCondition.meta_field != null" class="mt-2">
          <b-col>
            <b-form-group label="Input field" class="mb-0">
              <meta-data-selector
                :default-value="currentCondition.meta_field"
                @metaDataFieldSelected="setMetaDataField"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col>
            Test Example
            <b-form-group
              :label="fieldDisplay(currentCondition)"
              label-for="testInput"
            >
              <b-form-textarea
                id="testInput"
                v-model="currentCondition.query"
              />
            </b-form-group>
            <b-button
              block
              :variant="testConditionButtonVariant"
              @click="handleTestCondition"
            >
              {{ testConditionButtonText }}
            </b-button>
          </b-col>
        </b-row>
        <hr>
        <b-row v-if="conditionOptions.includes('fontSize')">
          <b-col>
            <b-form-group label="Font size">
              <b-form-radio-group
                id="font-size-buttons"
                v-model="currentCondition.font_size"
                :options="fontSizeOptions"
                buttons
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="conditionOptions.includes('deleteStrategy')">
          <b-col>
            <b-form-group
              label="Delete Strategy"
            >
              <b-form-radio-group
                id="delete-strategy"
                v-model="currentCondition.delete"
                :options="deleteOptions"
                buttons
              />
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { requiredIf } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import MetaDataSelector from '@/components/Ranker/MetaDataSelector.vue';
import MultiSelect from 'supwiz/components/MultiSelect.vue';

export default {
  name: 'TextCondition',
  components: {
    MetaDataSelector, MultiSelect,
  },
  mixins: [
    validationMixin,
  ],
  props: {
    id: {
      type: String,
      default: '',
    },
    conditions: {
      required: true,
      type: Array,
    },
    queryDisplay: {
      required: false,
      type: String,
      default: 'query',
    },
    allowMetaData: {
      type: Boolean,
      default: false,
    },
    validationMethod: {
      type: Function,
      default: undefined,
    },
    conditionOptions: {
      type: Array,
      default: () => ['regex', 'negate', 'caseSensitive', 'exactMatch'],
    },
    fontSizeOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    scrollable: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentCondition: this.newCondition(),
      conditionIndex: -1,
      deleteOptions: [
        {
          value: 'single',
          text: 'Only Text Blocks Matched',
        },
        {
          value: 'subsequent_subsections',
          text: 'Include Subsections',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('metaDataField', { metaDataFields: 'items' }),
    ...mapGetters('ranker', ['rankerArticleOptions']),
    testConditionButtonVariant() {
      switch (this.currentCondition.applies) {
        case true:
          return 'success';
        case false:
        case 'invalid regex':
          return 'danger';
        default:
          return 'secondary';
      }
    },
    testConditionButtonText() {
      switch (this.currentCondition.applies) {
        case true:
          return 'Test Condition - Applies';
        case false:
          return 'Test Condition - Does Not Apply';
        case 'invalid regex':
          return 'Test Condition - Invalid Regex';
        default:
          return 'Test Condition';
      }
    },
  },
  created() {
    this.fetchMetaDataFields();
  },
  methods: {
    ...mapActions('customRule', ['testCondition']),
    ...mapActions('metaDataField', { fetchMetaDataFields: 'fetchItems' }),
    newCondition() {
      return {
        text: '',
        case_sensitive: false,
        is_regex: false,
        negate: false,
        exact_match: false,
        query: '',
        detect_articles: false,
        article_ids: [],
        meta_field: null,
        font_size: null,
        delete: 'single',
      };
    },
    handleTestCondition() {
      const testData = this.currentCondition;
      if (testData.query == null) {
        testData.query = '';
      }
      if (this.validationMethod) {
        const check = this.validationMethod(testData, testData.query);
        Vue.set(this.currentCondition, 'applies', check);
      } else {
        this.testCondition(testData).then(({ data }) => {
          Vue.set(this.currentCondition, 'applies', data);
        });
      }
    },
    resetCondition() {
      this.currentCondition = this.newCondition();
      this.conditionIndex = -1;
    },
    prepareConditionEdit(condition) {
      this.currentCondition = cloneDeep(condition);
      this.conditionIndex = this.conditions.indexOf(condition);
      this.$bvModal.show('createTextCondition');
    },
    addCondition() {
      if (this.currentCondition.is_regex) {
        this.currentCondition.exact_match = false;
      }
      if (this.conditionIndex !== -1) {
        this.$emit('editTextCondition', { index: this.conditionIndex, condition: this.currentCondition });
      } else {
        this.$emit('addTextCondition', this.currentCondition);
      }
    },
    removeCondition(index) {
      this.$emit('removeTextCondition', index);
    },
    setMetaDataField(value) {
      this.currentCondition.meta_field = value;
    },
    fieldDisplay(condition) {
      if (this.allowMetaData && condition.meta_field != null) {
        return `${this.metaDataFields[condition.meta_field].key} (meta)`;
      }
      return this.queryDisplay;
    },
  },
  validations() {
    return {
      currentCondition: {
        text: {
          required: requiredIf((value) => !value.detect_articles && value.font_size == null),
        },
        article_ids: {
          custom: (value, vm) => !vm.detect_articles || !!value.length,
        },
      },
    };
  },

};
</script>

<style scoped>
.conditions__condition .condition__key,
.conditions__condition .condition__val {
  background-color: #f2f2f2;
  padding: 0.125rem .25rem;
  border-radius: .25rem;
  position: relative;
  overflow-wrap: anywhere;
  word-break: break-all;
  word-wrap: break-word;
}
.conditions .conditions__condition .condition__btn {
    height: 20px;
    min-width: 20px;
    width: 20px;
    max-width: 20px;
    line-height: 100%;
    padding: 0;
    margin: 0;
    color: rgba(0,0,0,0.25);
    transition-property: color;
}
.conditions .conditions__condition:hover .condition__btn {
    color: rgba(0,0,0,0.5)
}
.conditions {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
}
.conditions .conditions__condition {
  border-radius: .25rem;
  line-height: 175%;
}
</style>
